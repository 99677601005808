.icon-user-profile, .icon-messages {
    color: white;
    transition: 0.3s;
}

.icon-user-profile:hover, .icon-user-profile-active {
    color: lightskyblue;
}

.icon-messages:hover, .icon-messages-active {
    color: lightgreen;
}

.divider-custom {
    border: 1px solid white;
    margin: 10;
}
